<script>
import { generateId } from '@shared/services/Utils';
import utilsMixin from '@shared/mixins/utils';
import APIUpload from '@shared/services/API/Upload';
import TrainingItemBlocks from '@school/components/TrainingItemBlocks.vue';
import AssignmentDiscussion from '@shared/components/AssignmentDiscussion.vue';
import AssignmentValidationComment from '@shared/components/AssignmentValidationComment.vue';
import APICustomer from '@school/services/API/Customer';

export default {
  mixins: [utilsMixin],
  components: {
    TrainingItemBlocks,
    AssignmentDiscussion,
    AssignmentValidationComment,
  },
  i18n: {
    messages: {
      fr: {
        warning: 'Attention !',
        in_preview: 'Vous n\'êtes pas connecté en tant qu\'apprenant, vous êtes en mode "prévisualisation".',
        started_on: 'Commencé le',
        submitted_on: 'Soumis le',
        corrected_on: 'Corrigé le',
        grade: 'Note : {notation} / {maxNotation}',
        assignment_graded: 'Ce devoir est noté sur {maxNotation}',
        your_grade_is: 'Votre note est de :',
        assignment_submitted: 'Votre devoir à été envoyé, il est en attente de correction.',
        assignment_not_validated: 'Votre devoir n\'a pas été validé, vous devez soumettre une nouvelle version pour correction.',
        instructions: 'Consignes',
        appreciation: 'Appréciation',
        by: 'par',
        my_work: 'Mon travail',
        assignment_not_submitted: 'Votre devoir n\'a pas été soumis',
        submitted_on_date: 'Vous avez soumis votre devoir le',
        document_number: 'Document n° {number}',
        no_documents_added: 'Vous n\'avez ajouté aucun lien ou fichier pour le moment.',
        add_document: 'Ajouter un document (lien ou fichier)',
        type: 'Type',
        link: 'Lien',
        file: 'Fichier (image, audio, video, pdf ou zip)',
        your_document: 'Votre document',
        max_file_size: '(max {size} Mo)',
        view_file: 'voir le fichier',
        add: 'Ajouter',
        submit_assignment: 'Soumettre mon devoir',
        history: 'Historique',
        date: 'Date',
        document_count: 'Nombre de documents',
        duration: 'Durée',
        start_assignment: 'Commencer le devoir',
        start_assignment_instructions: 'Cliquez sur le bouton ci-dessous pour accéder aux consignes et commencer le devoir.',
        preview_only_visible_to_you: 'Cette vidéo n\'est visible que par vous',
        confirm_submit: 'Confirmez-vous l\'envoi du devoir ?',
        confirm: 'Confirmer',
        delete_document_confirmation: 'Vous êtes sur le point de supprimer votre document, confirmez-vous cette action ?',
        i_confirm: 'Je confirme',
        cancel: 'Annuler',
        file_size_error: 'Le fichier ne doit pas dépasser {size} Mo.',
        cannot_add_more_elements: 'Vous ne pouvez ajouter qu\'un seul élément. Vous pouvez supprimer l\'ancien pour en ajouter un nouveau.',
        at: 'à',
      },
      en: {
        warning: 'Warning!',
        in_preview: 'You are not logged in as a learner, you are in "preview" mode.',
        started_on: 'Started on',
        submitted_on: 'Submitted on',
        corrected_on: 'Corrected on',
        grade: 'Grade: {notation} / {maxNotation}',
        assignment_graded: 'The maximum score for this assignment is {maxNotation}',
        your_grade_is: 'Your grade is:',
        assignment_submitted: 'Your assignment has been sent and is awaiting correction.',
        assignment_not_validated: 'Your assignment was not approved. Please upload a corrected version for validation.',
        instructions: 'Instructions',
        appreciation: 'Appreciation',
        by: 'by',
        my_work: 'My work',
        assignment_not_submitted: 'Your assignment has not been submitted',
        submitted_on_date: 'You submitted your assignment on',
        document_number: 'Document #{number}',
        no_documents_added: 'No links or files have been added yet.',
        add_document: 'Add a document (link or file)',
        type: 'Type',
        link: 'Link',
        file: 'File (image, audio, video, pdf or zip)',
        your_document: 'Your document',
        max_file_size: '(max {size} MB)',
        view_file: 'view file',
        add: 'Add',
        submit_assignment: 'Submit my assignment',
        history: 'History',
        date: 'Date',
        document_count: 'Number of documents',
        duration: 'Duration',
        start_assignment: 'Start the assignment',
        start_assignment_instructions: 'Click the button below to access the instructions and start the assignment.',
        preview_only_visible_to_you: 'This video is only visible to you',
        confirm_submit: 'Do you confirm submitting the assignment?',
        confirm: 'Confirm',
        delete_document_confirmation: 'You are about to delete your document, do you confirm this action?',
        i_confirm: 'I confirm',
        cancel: 'Cancel',
        file_size_error: 'The file must not exceed {size} MB.',
        cannot_add_more_elements: 'You can only add one element. You can delete the old one to add a new one.',
        at: 'at',
      },
    },
  },
  props: {
    trainingItem: {
      type: Object,
      required: true,
    },
    progressionIsUpdating: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      isSending: false,
      isCommenting: false,
      submission: null,
      newSubmissionFile: null,
      newSubmission: {
        paths: [{
          id: generateId(),
          type: 'LINK',
          path: '',
        }],
        submit: true,
      },
      newComment: '',
    };
  },
  computed: {
    authCustomer() {
      return this.$store.getters['auth/customer'];
    },
    previewToken() {
      return this.$route.query.p;
    },
    completedAt() {
      return (
        this.trainingItem.progression
        && this.trainingItem.progression.completed_at
      );
    },
    maxFileSize() {
      return this.sharedHasExpertPlan
        ? this.$constants.MAX_ASSIGNMENT_SIZE_EXPERT
        : this.$constants.MAX_ASSIGNMENT_SIZE_PRO;
    },
    hasNotation() {
      return (
        this.sharedHasExpertPlan
        && this.trainingItem.specific_content.max_notation_enabled
        && this.trainingItem.specific_content.max_notation
      );
    },
    maxNotation() {
      return (
        (this.submission && this.submission.max_notation)
        || this.trainingItem.specific_content.max_notation
      );
    },
    canSubmitMultipleAssets() {
      return (
        this.sharedHasExpertPlan
        && this.trainingItem.specific_content.multi_assets_enabled
      );
    },
    canSubmitForm() {
      return (
        this.submission.status === 'PENDING_CUSTOMER'
        && (
          this.canSubmitMultipleAssets
          || !this.submission.paths
          || this.submission.paths.length < 1
        )
      );
    },
    canSeeDiscussion() {
      return (
        this.sharedHasExpertPlan
        && this.submission.discussions.length > 0
      ) || this.hasNewDiscussionEnabled;
    },
    hasNewDiscussionEnabled() {
      return (
        this.sharedHasExpertPlan
        && this.trainingItem
          .specific_content
          .private_discussion_enabled
      );
    },
  },
  methods: {
    start() {
      if (this.previewToken) {
        this.$buefy.dialog.alert({
          type: 'is-warning',
          title: this.$t('warning'),
          message: this.$t('in_preview'),
        });
        return;
      }

      const { slug, id } = this.$route.params;
      const loader = this.$buefy.loading.open();
      APICustomer.startTrainingItemAssignments(slug, id)
        .then(({ data }) => (this.submission = data))
        .finally(() => loader.close());
    },
    fetchSubmission() {
      if (this.isLoading) {
        return;
      }

      if (this.previewToken) {
        return;
      }

      this.isLoading = true;
      const { slug, id } = this.$route.params;
      APICustomer.getTrainingItemAssignmentSubmission(slug, id)
        .then(({ data }) => (this.submission = data))
        .catch((error) => {
          if (error.response.status === 404) {
            return;
          }

          this.$errorHandlers.axios(error);
        })
        .finally(() => (this.isLoading = false));
    },
    submit() {
      if (!this.canSubmitForm) {
        this.$buefy.dialog.alert(this.$t('cannot_add_more_elements'));
        return;
      }

      this.isSending = true;

      let promise = Promise.resolve();
      const { slug, id } = this.$route.params;

      if (this.newSubmission.paths[0].type === 'FILE') {
        promise = this.uploadSubmissionFile();
      }

      const submission = {
        ...this.newSubmission,
        submit: false,
      };

      promise.then(() => APICustomer.submitTrainingItemAssignments(
        slug, id,
        this.submission.id,
        submission,
      ))
        .then(({ data }) => {
          this.submission = data;
          this.newSubmissionFile = null;
          this.newSubmission.paths[0].path = '';
          this.newSubmission.paths[0].id = generateId();
        })
        .finally(() => (this.isSending = false));
    },
    finalSubmit() {
      this.$buefy.dialog.confirm({
        type: 'is-danger',
        title: this.$t('warning'),
        message: this.$t('confirm_submit'),
        focusOn: 'cancel',
        confirmText: this.$t('confirm'),
        cancelText: this.$t('cancel'),
        onConfirm: () => {
          this.isSending = true;
          const { slug, id } = this.$route.params;
          APICustomer.submitTrainingItemAssignments(
            slug, id,
            this.submission.id,
            { paths: [], submit: true },
          )
            .then(({ data }) => (this.submission = data))
            .finally(() => (this.isSending = false));
        },
      });
    },
    deletePath(path) {
      this.$buefy.dialog.confirm({
        type: 'is-danger',
        title: this.$t('warning'),
        message: this.$t('delete_document_confirmation'),
        focusOn: 'cancel',
        confirmText: this.$t('i_confirm'),
        cancelText: this.$t('cancel'),
        onConfirm: () => {
          const { slug, id } = this.$route.params;
          const loader = this.$buefy.loading.open();
          APICustomer.deleteTrainingItemAssignmentSubmissionPath(
            slug, id,
            this.submission.id,
            path.id,
          )
            .then(() => (
              this.submission.paths = this.submission
                .paths
                .filter((v) => v.id !== path.id)
            ))
            .finally(() => loader.close());
        },
      });
    },
    onUpload(event) {
      const file = event.target.files[0];

      if (!file) {
        this.newSubmissionFile = null;
        return;
      }

      if (file.size / 1024 > this.maxFileSize) {
        this.newSubmissionFile = null;
        this.$refs.upload.value = null;
        this.$buefy.dialog.alert(this.$t('file_size_error', { size: this.maxFileSize / 1024 }));
        return;
      }

      this.newSubmissionFile = file;
    },
    uploadSubmissionFile() {
      const params = {
        type: 'resource',
        file: this.newSubmissionFile,
        fileableId: this.trainingItem.id,
        fileableType: 'TRAINING_ITEM',
      };

      return APIUpload.upload(params)
        .then((file) => (
          this.newSubmission.paths[0].path = file.url
        ));
    },
    onPathTypeChange() {
      this.newSubmissionFile = null;
      this.newSubmission.paths[0].path = '';
    },
    sendComment() {
      if (this.isCommenting) {
        return;
      }

      const { slug, id } = this.$route.params;
      this.isCommenting = true;
      APICustomer.addTrainingItemAssignmentComment(
        slug, id,
        this.submission.id,
        { comment: this.newComment },
      )
        .then(({ data }) => {
          this.newComment = '';
          this.submission = data;
        })
        .finally(() => (this.isCommenting = false));
    },
  },
  created() {
    this.fetchSubmission();
  },
};
</script>

<template>
  <div class="ctitcomp">
    <b-skeleton v-if="isLoading" height="400" />
    <template v-else>
      <teleport
        v-if="hasNotation || submission"
        to="training_item_under_title"
      >
        <p class="tags is-centered mb-10">
          <span v-if="submission" class="tag is-outlined">
            {{ $t('started_on') }}
            {{ submission.created_at | momentFromUTC | moment('DD/MM/YY [' + $t('at') +  '] HH:mm') }}
          </span>
          <span v-if="submission && submission.customer_submission_at" class="tag is-outlined">
            {{ $t('submitted_on') }}
            {{ submission.customer_submission_at | momentFromUTC | moment('DD/MM/YY [' + $t('at') +  '] HH:mm') }}
          </span>
          <span
            v-if="submission && submission.teacher_validation_at"
            class="tag is-outlined"
          >
            {{ $t('corrected_on') }}
            {{ submission.teacher_validation_at | momentFromUTC | moment('DD/MM/YY [' + $t('at') +  '] HH:mm') }}
          </span>
          <template v-if="hasNotation && !completedAt">
            <span
              v-if="submission && submission.notation"
              class="tag is-black"
            >
              {{ $t('grade', { notation: submission.notation, maxNotation }) }}
            </span>
            <span v-else class="tag is-black is-medium">
              {{ $t('assignment_graded', { maxNotation }) }}
            </span>
          </template>
        </p>
      </teleport>

      <p
        v-if="
          completedAt
          && hasNotation
          && submission.notation
        "
        class="box is-primary is-custom has-text-centered"
      >
        {{ $t('your_grade_is') }}

        <span class="title is-3 is-block has-text-black">
          {{ submission.notation }} / {{ maxNotation }}
        </span>
      </p>

      <div
        v-if="submission && submission.status == 'PENDING_TEACHER'"
        class="box is-primary is-custom is-flex"
      >
      <b-icon
          type="is-primary"
          class="flex-none is-custom mr-3"
          icon="info-circle"
        />
        {{ $t('assignment_submitted') }}
      </div>

      <div
        v-if="
          submission
          && submission.teacher_validation_at
          && submission.teacher_validation_comment
          && submission.status != 'COMPLETED'
        "
        class="box is-primary is-custom is-flex"
      >
        <b-icon
          type="is-primary"
          class="flex-none is-custom mr-3"
          icon="info-circle"
        />
        {{ $t('assignment_not_validated') }}
      </div>

      <AppToggleSection v-if="submission">
        <template #title>
          {{ $t('instructions') }}
        </template>

        <template #default>
          <TrainingItemBlocks
            :trainingItem="trainingItem"
          />
        </template>
      </AppToggleSection>

      <template v-if="submission">
        <AppToggleSection
          v-if="submission.teacher_validation_at"
          :isOpen="!!completedAt"
        >
          <template #title>
            {{ $t('appreciation') }}
          </template>

          <template #default>
            <p class="is-size-8 has-text-weight-bold mb-3">
              {{ $t('corrected_on') }} {{ submission.teacher_validation_at | momentFromUTC | moment('DD/MM/YY [' + $t('at') +  '] HH:mm') }}
              <span v-if="submission.teacher">
                {{ $t('by') }} {{ submission.teacher.firstname }}
              </span>
            </p>
            <AssignmentValidationComment
              class="is-size-8"
              :comment="submission.teacher_validation_comment"
            />
          </template>
        </AppToggleSection>

        <AppToggleSection>
          <template #title>
            {{ $t('my_work') }}
            <span
              v-if="submission.status == 'PENDING_CUSTOMER'"
              class="tag is-outlined ml-3"
            >
              {{ $t('assignment_not_submitted') }}
            </span>
          </template>

          <template #default>
            <p v-if="submission.status == 'PENDING_TEACHER'" class="mb-3">
              {{ $t('submitted_on_date') }} {{ submission.customer_submission_at | momentFromUTC | moment('DD/MM/YY [' + $t('at') +  '] HH:mm') }}.
            </p>
            <ul
              v-if="submission.paths && submission.paths.length"
              class="panel is-primary is-custom"
            >
              <li
                v-for="(path, i) in submission.paths"
                :key="path.id"
                class="panel-block"
              >
                <p class="w-full flex-between-center py-2">
                  <a
                    class="has-text-clipped"
                    :href="path.path"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {{ $t('document_number', { number: i + 1 }) }}
                  </a>
                  <a
                    v-if="submission.status == 'PENDING_CUSTOMER'"
                    class="icon is-right cursor-pointer"
                    href="#"
                    @click.prevent="deletePath(path)"
                  >
                    <b-icon type="is-danger" icon="trash" />
                  </a>
                </p>
              </li>
            </ul>
            <p v-else class="box">
              {{ $t('no_documents_added') }}
            </p>

            <template v-if="submission.status == 'PENDING_CUSTOMER'">
              <h3 class="title is-5 is-custom is-size-6-mobile mb-5 mt-5">
                {{ $t('add_document') }}
              </h3>

              <form
                class="box"
                @submit.prevent="dataIsValid(isSending) && submit()"
              >
                <div class="columns">
                  <div class="column is-3">
                    <b-field
                      class="mb-0"
                      :label="$t('type')"
                    >
                      <b-select
                        v-model="newSubmission.paths[0].type"
                        expanded
                        required
                        @input="onPathTypeChange"
                      >
                        <option value="LINK">
                          {{ $t('link') }}
                        </option>
                        <option value="FILE">
                          {{ $t('file') }}
                        </option>
                      </b-select>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field class="mb-0">
                      <template #label>
                        {{ $t('your_document') }}
                        <template v-if="newSubmission.paths[0].type == 'FILE'">
                          {{ $t('max_file_size', { size: maxFileSize / 1024 }) }}
                        </template>
                      </template>
                      <b-input
                        v-if="newSubmission.paths[0].type == 'LINK'"
                        key="LINK"
                        v-model="newSubmission.paths[0].path"
                        pattern="^https?://.+"
                        required
                        expanded
                      />
                      <template v-else>
                        <input
                          v-if="newSubmission.paths[0].path == ''"
                          key="FILE"
                          ref="upload"
                          class="input is-fullwidth"
                          type="file"
                          accept="image/*,video/*,audio/*,application/pdf,zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                          required
                          @input="onUpload"
                        />
                        <p v-else class="input">
                          <a
                            class="tdecoration-underline has-text-black"
                            :href="newSubmission.paths[0].path"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {{ $t('view_file') }}
                          </a>
                        </p>
                      </template>
                    </b-field>
                  </div>
                  <div class="column is-narrow">
                    <b-button
                      type="is-primary"
                      native-type="submit"
                      class="is-custom"
                      style="margin-top: 29px"
                      :loading="isSending"
                    >
                      {{ $t('add') }}
                    </b-button>
                  </div>
                </div>
              </form>
            </template>

            <p
              v-if="
                submission.status == 'PENDING_CUSTOMER'
                && submission.paths
                && submission.paths.length > 0
              "
              class="has-text-centered"
            >
              <b-button
                type="is-primary"
                native-type="submit"
                class="is-custom"
                :loading="isSending"
                @click="finalSubmit"
              >
                {{ $t('submit_assignment') }}
              </b-button>
            </p>
          </template>
        </AppToggleSection>

        <AppToggleSection v-if="submission.history.length > 2">
          <template #title>
            {{ $t('history') }}
          </template>

          <template #default>
            <b-table class="box" :data="submission.history">
              <b-table-column
                :label="$t('date')"
                field="submission_at"
                v-slot="{ row }"
              >
                <b-tooltip>
                  <template #content>
                    {{ row.type === 'assignment_started' ? row.created_at :row.submission_at
                    | momentFromUTC
                    | moment('DD/MM/YY [' + $t('at') +  '] HH:mm') }}
                  </template>
                  {{ row.type === 'assignment_started' ? row.created_at :row.submission_at
                  | momentFromUTC
                  | moment('from') }}
                </b-tooltip>
              </b-table-column>
              <b-table-column :label="$t('type')" field="type" v-slot="{ row }">
                <span v-t="`history_type.${row.type}`" />
              </b-table-column>
              <b-table-column
                :label="$t('document_count')"
                field="submission_path_count"
                numeric
                v-slot="{ row }"
              >
                {{ row.submission_path_count }}
              </b-table-column>
              <b-table-column
                :label="$t('duration')"
                field="type"
                numeric
                v-slot="{ row }"
              >
                <template v-if="row.type == 'customer_submission'">
                  {{ row.duration_since_assignment_creation | formatSeconds }}
                </template>
              </b-table-column>
            </b-table>
          </template>
        </AppToggleSection>

        <template v-if="canSeeDiscussion">
          <AssignmentDiscussion
            class="mt-10"
            v-model="newComment"
            context="CUSTOMER"
            :isSending="isCommenting"
            :submission="submission"
            :disabled="!hasNewDiscussionEnabled"
            @send="sendComment"
          />
        </template>
      </template>

      <template v-else>
        <section
          class="
            px-3 py-10 bradius-8 has-background-light is-custom
            content has-text-centered
          "
        >
          <p>
            {{ $t('start_assignment_instructions') }}
          </p>
          <p class="mt-10">
            <b-button
              type="is-primary"
              class="is-custom"
              @click="start"
            >
              {{ $t('start_assignment') }}
            </b-button>
          </p>
        </section>

        <section v-if="previewToken" class="box is-primary is-custom">
          <h2
            class="
              title is-3 is-custom is-size-4-mobile
              has-text-centered mb-5
            "
          >
            {{ $t('preview_only_visible_to_you') }}
          </h2>

          <div class="image is-16by9">
            <iframe class="pabsolute-0 w-full h-full" src="https://www.youtube.com/embed/S_IfqlNaMrc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />
          </div>
        </section>
      </template>
    </template>
  </div>
</template>
