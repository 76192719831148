<script>
import shuffle from 'lodash/shuffle';
import { required } from 'vuelidate/lib/validators';
import responsiveMixin from '@shared/mixins/responsive';
import ListQuizResponse from '@shared/components/ListQuizResponse.vue';
import QuizQuestionMediaContent from '@shared/components/QuizQuestionMediaContent.vue';
import APICustomer from '@school/services/API/Customer';
import CustomerTrainingItemQuizAnswer from '@school/components/CustomerTrainingItemQuizAnswer.vue';

export default {
  mixins: [responsiveMixin({ isMobileScreen: 768 })],
  components: {
    ListQuizResponse,
    QuizQuestionMediaContent,
    CustomerTrainingItemQuizAnswer,
  },
  props: {
    trainingSlug: {
      type: String,
      required: true,
    },
    trainingItem: {
      type: Object,
      required: true,
    },
    onQuizAnswered: {
      type: Function,
      default: () => (() => {}),
    },
  },
  data() {
    return {
      isLoading: false,
      isSending: false,
      quizIsOpen: false,
      hasAnswered: false,
      userAnswers: [],
      quizResponse: {},
      currentQuizDetailsPage: 1,
      startTime: null,
      countdownTimer: null,
      remainingTime: null,
      beforeUnloadHandler: null,
    };
  },
  computed: {
    partialModeEnabled() {
      return this.$store.getters['store/hasExpertPlan']
        && this.trainingItem.specific_content
        && this.trainingItem.specific_content.partial_count > 0;
    },
    timerEnabled() {
      return this.$store.getters['store/hasExpertPlan']
        && this.trainingItem.specific_content
        && this.trainingItem.specific_content.timer_value > 0;
    },
    authCustomer() {
      return this.$store.getters['auth/customer'];
    },
    completedAt() {
      return this.trainingItem.progression && this.trainingItem.progression.completed_at;
    },
    perPage() {
      const perPage = this.trainingItem.specific_content.questions_per_page;
      return perPage <= 0 ? this.trainingItem.quiz_details.length : perPage;
    },
    totalPages() {
      return this.perPage <= 0 ? 1 : Math.ceil(this.trainingItem.quiz_details.length / this.perPage);
    },
    currentQuizDetails() {
      const { quiz_details } = this.trainingItem;

      if (this.totalPages <= 1) {
        return quiz_details;
      }

      return quiz_details.slice(this.questionIndexStep, this.questionIndexStep + this.perPage);
    },
    questionIndexStep() {
      if (this.totalPages > 1) {
        return this.perPage * (this.currentQuizDetailsPage - 1);
      }

      return 0;
    },
    hasPrevPage() {
      return this.totalPages > 1 && this.currentQuizDetailsPage > 1;
    },
    hasNextPage() {
      return this.totalPages > 1 && this.currentQuizDetailsPage < this.totalPages;
    },
    isLastPage() {
      return this.currentQuizDetailsPage === this.totalPages;
    },
    endTime() {
      return this.startTime ? new Date(this.startTime.getTime() + this.trainingItem.specific_content.timer_value * 1000) : null;
    },
    isTimerWarning() {
      if (!this.remainingTime) return false;

      const [minutes, seconds] = this.remainingTime.split(':').map(Number);
      const remainingSeconds = minutes * 60 + seconds;

      // Calculate 10% of total time with bounds (15s min, 120s max)
      const totalSeconds = this.trainingItem.specific_content.timer_value;
      const warningThreshold = Math.min(
        Math.max(totalSeconds * 0.1, 15),
        120,
      );

      return remainingSeconds <= warningThreshold;
    },
    canCloseQuiz() {
      return !this.trainingItem.specific_content.timer_value || !this.quizIsOpen || this.hasAnswered;
    },
    localStorageKey() {
      return `quiz_answers_${this.trainingSlug}_${this.trainingItem.id}`;
    },
    localStorageStateKey() {
      return `quiz_state_${this.trainingSlug}_${this.trainingItem.id}`;
    },
  },
  validations() {
    if (this.completedAt) {
      return {};
    }

    return {
      userAnswers: {
        $each: {
          answers: { required },
        },
      },
    };
  },
  watch: {
    'trainingItem.quiz_details': {
      immediate: true,
      handler(val) {
        this.userAnswers = val.map((q) => ({ question_id: q.id, answers: [] }));

        if (!this.timerEnabled) {
          return;
        }

        // Load saved answers from localStorage if they exist
        const savedAnswers = localStorage.getItem(this.localStorageKey);
        const savedState = localStorage.getItem(this.localStorageStateKey);

        if (savedAnswers && !this.$route.query.p) {
          try {
            const parsed = JSON.parse(savedAnswers);
            // Only use saved answers if they match the current questions
            if (parsed.length === this.userAnswers.length) {
              const savedAnswersByKey = parsed.reduce((prev, curr) => {
                prev[curr.question_id] = curr;
                return prev;
              }, {});

              this.userAnswers = this.userAnswers.map((q) => {
                const id = q.question_id;

                return {
                  question_id: id,
                  answers: ((savedAnswersByKey[id] && savedAnswersByKey[id].answers) || []),
                };
              });
            }
          } catch (e) {
            console.error('Failed to parse saved answers:', e);
          }
        }

        if (savedState) {
          try {
            const state = JSON.parse(savedState);
            if (state.currentPage && state.currentPage <= this.totalPages) {
              this.currentQuizDetailsPage = state.currentPage;
            }
          } catch (e) {
            console.error('Failed to parse saved state:', e);
          }
        }
      },
    },
    userAnswers: {
      deep: true,
      handler(val) {
        if (val && val.length > 0) {
          localStorage.setItem(this.localStorageKey, JSON.stringify(val));
        }
      },
    },
    currentQuizDetailsPage(val) {
      if (this.quizIsOpen) {
        localStorage.setItem(this.localStorageStateKey, JSON.stringify({ currentPage: val }));
      }
    },
  },
  created() {
    this.loadQuizResponse();
  },
  methods: {
    loadQuizResponse() {
      if (!this.authCustomer || !this.trainingItem.quiz_submissions_count) {
        return;
      }

      this.isLoading = true;
      APICustomer.getQuizResponse(this.trainingSlug, this.trainingItem.id)
        .then(this.setQuizResponse)
        .finally(() => (this.isLoading = false));
    },
    setupBeforeUnloadHandler() {
      if (this.beforeUnloadHandler) {
        window.removeEventListener('beforeunload', this.beforeUnloadHandler);
        window.removeEventListener('unload', this.beforeUnloadHandler);
      }
      this.beforeUnloadHandler = (e) => {
        if (!this.canCloseQuiz) {
          e.preventDefault();
          e.returnValue = this.$t('quiz_leave_warning');
          return e.returnValue;
        }
        return null;
      };
      window.addEventListener('beforeunload', this.beforeUnloadHandler);
      window.addEventListener('unload', this.beforeUnloadHandler);
    },
    setQuizResponse({ data }) {
      if (!data) {
        return;
      }

      this.hasAnswered = true;
      this.quizResponse = data;
      const endedAt = new Date(`${data.ended_at}Z`);
      const now = new Date();
      if (endedAt > now) {
        const endedAtTime = endedAt.getTime();
        const timerDuration = this.trainingItem.specific_content.timer_value * 1000;
        this.startTime = new Date(endedAtTime - timerDuration);
        this.quizIsOpen = true;
        this.hasAnswered = false;
        this.startCountdown();
        if (this.timerEnabled) {
          this.setupBeforeUnloadHandler();
        }
        if (data.quiz_details) {
          this.trainingItem.quiz_details = data.quiz_details;
        }
      }
    },
    startQuiz() {
      if (this.timerEnabled) {
        const minutes = Math.floor(this.trainingItem.specific_content.timer_value / 60);
        const seconds = this.trainingItem.specific_content.timer_value % 60;
        const timeDisplay = seconds > 0 ? `${minutes}:${seconds.toString().padStart(2, '0')}` : minutes;

        this.$buefy.dialog.confirm({
          message: this.$t('timer_quiz_confirmation', { time: timeDisplay }),
          confirmText: this.$t('start_quiz'),
          cancelText: this.$t('cancel'),
          type: 'is-warning',
          onConfirm: () => this.initializeQuiz(),
        });
        return;
      }

      this.initializeQuiz();
    },
    initializeQuiz() {
      let selectedQuestions = this.trainingItem.quiz_details;

      if (this.partialModeEnabled) {
        selectedQuestions = shuffle(this.trainingItem.quiz_details).slice(0, this.trainingItem.specific_content.partial_count);
      } else if (this.trainingItem.specific_content.shuffle) {
        selectedQuestions = shuffle(this.trainingItem.quiz_details);
      }

      const startQuizAndCountdown = () => {
        this.trainingItem.quiz_details = selectedQuestions;
        this.startTime = new Date();
        this.quizIsOpen = true;
        if (this.timerEnabled) {
          this.startCountdown();
          this.setupBeforeUnloadHandler();
        }
      };

      if (this.timerEnabled && !this.$route.query.p) {
        this.isLoading = true;
        APICustomer.initializeTimedQuiz(this.trainingSlug, this.trainingItem.id, {
          timer_value: this.trainingItem.specific_content.timer_value,
          start_time: new Date().toISOString(),
          selected_question_ids: selectedQuestions.map((question) => question.id),
        })
          .then(() => {
            startQuizAndCountdown();
          })
          .catch(() => {
            this.$buefy.toast.open({
              message: this.$t('error_starting_timed_quiz'),
              type: 'is-danger',
            });
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        startQuizAndCountdown();
      }
    },
    closeQuiz() {
      if (!this.canCloseQuiz) {
        return;
      }
      this.quizIsOpen = false;
      this.currentQuizDetailsPage = 1;
      this.$v.$reset();
      this.stopCountdown();
      // Clear localStorage when closing quiz
      localStorage.removeItem(this.localStorageKey);
      localStorage.removeItem(this.localStorageStateKey);
      if (this.beforeUnloadHandler) {
        window.removeEventListener('beforeunload', this.beforeUnloadHandler);
        window.removeEventListener('unload', this.beforeUnloadHandler);
        this.beforeUnloadHandler = null;
      }
    },
    startCountdown() {
      this.updateCountdown();
      this.countdownTimer = setInterval(this.updateCountdown, 1000);
    },
    stopCountdown() {
      if (this.countdownTimer) {
        clearInterval(this.countdownTimer);
        this.countdownTimer = null;
      }
    },
    updateCountdown() {
      if (!this.endTime) return;

      const now = new Date().getTime();
      const timeLeft = this.endTime.getTime() - now;

      if (timeLeft <= 0) {
        this.stopCountdown();
        this.handle(true);
        return;
      }

      const minutes = Math.floor(timeLeft / (1000 * 60));
      const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
      this.remainingTime = `${minutes}:${seconds.toString().padStart(2, '0')}`;
    },
    currentQuizDetailsHasError() {
      return this.currentQuizDetails.some((q, qk) => {
        const $v = this.$v.userAnswers.$each[qk + this.questionIndexStep].answers;
        return !this.dataIsValid(this.isSending, $v);
      });
    },
    prevPage() {
      if (this.currentQuizDetailsHasError()) {
        this.$nextTick(() => this.$scrollTo('.has-error', { container: this.$refs.body }));
        return;
      }

      if (this.currentQuizDetailsPage > 1) {
        this.currentQuizDetailsPage--;
      } else {
        this.currentQuizDetailsPage = 1;
      }

      this.$refs.body.scroll(0, 0);
    },
    nextPage() {
      if (this.currentQuizDetailsHasError()) {
        this.$nextTick(() => this.$scrollTo('.has-error', { container: this.$refs.body }));
        return;
      }

      if (this.currentQuizDetailsPage < this.totalPages) {
        this.currentQuizDetailsPage++;
      } else {
        this.currentQuizDetailsPage = this.totalPages;
      }

      this.$refs.body.scroll(0, 0);
    },
    handle(fromTimeout = false) {
      if (!this.authCustomer) {
        this.$buefy.dialog.alert(this.$t('not_logged_in_as_learner'));
        return;
      }

      const submitAnswers = () => {
        const data = [...this.userAnswers];
        this.isSending = true;
        APICustomer
          .answerQuiz(this.trainingSlug, this.trainingItem.id, data)
          .then((data) => {
            this.setQuizResponse(data);
            this.closeQuiz();
            // Clear localStorage after successful submission
            localStorage.removeItem(this.localStorageKey);
            localStorage.removeItem(this.localStorageStateKey);
            return this.onQuizAnswered();
          })
          .finally(() => (this.isSending = false));
      };

      if (fromTimeout) {
        submitAnswers();
      } else {
        this.$buefy.dialog.confirm({
          message: this.$t('confirm_submit_answers'),
          focusOn: 'cancel',
          confirmText: this.$t('ok'),
          cancelText: this.$t('cancel'),
          onConfirm: submitAnswers,
        });
      }
    },
  },
  beforeDestroy() {
    this.stopCountdown();
    if (this.beforeUnloadHandler) {
      window.removeEventListener('beforeunload', this.beforeUnloadHandler);
      window.removeEventListener('unload', this.beforeUnloadHandler);
    }
  },
  i18n: {
    messages: {
      fr: {
        your_success_rate: 'Votre taux de réussite :',
        minimum_success_rate: 'Le taux de réussite minimum pour valider ce quiz :',
        retry_quiz: 'Recommencer le quiz',
        start_quiz: 'Commencer le quiz',
        close: 'Fermer',
        send_answers: 'Envoyer mes réponses',
        previous: 'Précédent',
        next: 'Suivant',
        not_logged_in_as_learner: 'Vous n\'êtes pas connecté en tant qu\'apprenant !',
        confirm_submit_answers: 'Attention ! <br> Vos réponses seront définitives.',
        ok: 'Ok !',
        cancel: 'Annuler',
        timer_quiz_confirmation: 'Attention ! Ce quiz chronométré durera {time} minute(s). Une fois commencé, vous ne pourrez pas le mettre en pause ou le quitter avant d\'avoir terminé.',
        error_starting_timed_quiz: 'Une erreur est survenue lors du démarrage du quiz chronométré. Veuillez réessayer.',
        quiz_leave_warning: 'Êtes-vous sûr de vouloir quitter ? Votre progression dans le quiz sera perdue.',
      },
      en: {
        your_success_rate: 'Your success rate:',
        minimum_success_rate: 'The minimum passing score required to complete this quiz is',
        retry_quiz: 'Repeat the quiz',
        start_quiz: 'Start the quiz',
        close: 'Close',
        send_answers: 'Send my answers',
        previous: 'Previous',
        next: 'Next',
        not_logged_in_as_learner: 'You are not logged in as a learner!',
        confirm_submit_answers: 'Are you sure? <br> Once you submit your answers, they will be final and cannot be changed.',
        ok: 'Ok!',
        cancel: 'Cancel',
        timer_quiz_confirmation: 'Caution! This time-controlled ou time-limited quiz will last {time} minute(s). Once started, you will not be able to pause or exit the quiz before its completion.',
        error_starting_timed_quiz: 'An error occurred while starting the timed quiz. Please try again.',
        quiz_leave_warning: 'Are you sure you want to leave? Your quiz progress will be lost.',
      },
    },
  },
};
</script>

<template>
  <div class="ctiqcomp">
    <b-skeleton v-if="isLoading" height="400" />
    <template v-else>
      <div v-if="hasAnswered">
        <p class="mb-2 has-text-centered">
          {{ $t('your_success_rate') }}<br>
          <strong class="is-size-1">
            {{ quizResponse.percentage_successful_questions }}%
          </strong>
        </p>
        <p v-if="trainingItem.specific_content.min_success_rate" class="help has-text-centered mb-3">
          {{ $t('minimum_success_rate') }}
          {{ trainingItem.specific_content.min_success_rate }}%
        </p>
        <p v-if="!completedAt" class="mb-10 has-text-centered">
          <b-button v-show="!isSending" type="is-primary is-custom" @click="startQuiz">
            {{ $t('retry_quiz') }}
          </b-button>
        </p>
        <ListQuizResponse
          v-if="completedAt && trainingItem.specific_content.hide_answers != true && quizResponse.percentage_successful_questions >= trainingItem.specific_content.min_success_rate"
          :trainingItem="trainingItem"
          :quizResponse="quizResponse"
        />
      </div>
      <p v-else class="has-text-centered">
        <b-button type="is-primary is-custom" @click="startQuiz">
          {{ $t('start_quiz') }}
        </b-button>
      </p>

      <b-modal
        v-if="!completedAt"
        :active.sync="quizIsOpen"
        :can-cancel="false"
        :full-screen="isMobileScreen"
        has-modal-card
      >
        <section class="modal-card">
          <header class="modal-card-head py-3">
            <h1 class="modal-card-title flex-auto has-text-clipped">
              {{ trainingItem.name }}
            </h1>

            <p
              v-if="trainingItem.specific_content.timer_value"
              class="has-text-centered"
              :class="{
                'has-text-danger is-size-4 has-text-weight-bold timer-warning': isTimerWarning,
              }"
            >
              {{ remainingTime }}
            </p>

            <b-button
              v-if="canCloseQuiz"
              class="flex-none h-auto p-0 ml-5"
              type="is-text"
              icon-left="times"
              :aria-label="$t('close')"
              @click="closeQuiz"
            />
          </header>
          <form ref="body" class="modal-card-body" @submit.prevent="dataIsValid(isSending) && handle()">
            <div class="mw-640 mx-auto">
              <article
                v-for="(q, qk) in currentQuizDetails"
                :key="q.id"
                :class="{'mt-5': qk != 0}"
              >
                <h3 class="title is-5">
                  {{ q.question }}
                </h3>

                <QuizQuestionMediaContent
                  v-if="!$store.getters['store/hasPercentPlan']"
                  :question="q"
                />

                <hr>

                <div class="has-background-light is-custom py-5 px-3 bradius-4">
                  <b-field v-for="a in q.answers" :key="a.id">
                    <CustomerTrainingItemQuizAnswer
                      :question="q"
                      :answer="a"
                      :userAnswer="userAnswers[qk + questionIndexStep]"
                    />
                  </b-field>
                </div>

                <b-field
                  v-if="$v.userAnswers.$each[qk + questionIndexStep] && $v.userAnswers.$each[qk + questionIndexStep].$error"
                  :class="{'has-error': $v.userAnswers.$each[qk + questionIndexStep].$error}"
                  v-bind="$getErrorProps($v.userAnswers.$each[qk + questionIndexStep].answers, ['atLeastOneAnswer'])"
                />
              </article>
              <div v-show="isLastPage" class="mt-5 buttons is-centered">
                <b-button
                  native-type="submit"
                  type="is-primary is-custom"
                  :loading="isSending"
                >
                  {{ $t('send_answers') }}
                </b-button>
              </div>
            </div>
          </form>
          <footer
            class="modal-card-foot is-justify-content-flex-end"
            :class="{'has-background-light': totalPages > 1}">
            <template v-if="totalPages > 1">
              <b-button icon-left="chevron-left" :disabled="!hasPrevPage" @click="prevPage">
                {{ $t('previous') }}
              </b-button>
              <b-button icon-left="chevron-right" :disabled="!hasNextPage" @click="nextPage">
                {{ $t('next') }}
              </b-button>
            </template>
          </footer>
        </section>
      </b-modal>
    </template>
  </div>
</template>

<style lang="scss" scoped>
@keyframes pulse-warning {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.timer-warning {
  animation: pulse-warning 1s ease-in-out infinite;
  display: inline-block;
}
</style>
